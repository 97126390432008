<template v-if="selfProfile">
  <!-- First Row -->
  <b-row>
    <b-col
      cols="12"
      xl="9"
      lg="8"
      md="7"
    >
      <mini-profile :data-profile="selfProfile" />
    </b-col>
    <b-col
      cols="12"
      md="5"
      xl="3"
      lg="4"
    >
      <presensi-card />
    </b-col>
  </b-row>

</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import MiniProfile from '@/views/components/MiniProfile.vue'
import PresensiCard from '@/views/components/PresensiCard.vue'
import RepositoryHrisDjpb from '@/repositories/factories/hrisDjpbFactory'

const pegawaiProfile = RepositoryHrisDjpb.get('pegawaiProfile')
const pegawaiGrade = RepositoryHrisDjpb.get('pegawaiGrade')

export default {
  components: {
    BRow,
    BCol,
    MiniProfile,
    PresensiCard,
  },
  data() {
    return {
      pegawaiProfile: {},
      pegawaiGrade: {},
      selfProfile: {
        foto: 'pegawai_foto',
      },
    }
  },
  computed: {
    ...mapGetters(['thisPegawaiProfile']),
  },
  watch: {
    pegawaiProfile(newVal) {
      this.selfProfile = { ...newVal, ...this.pegawaiGrade }
    },
    pegawaiGrade(newVal) {
      this.selfProfile = { ...newVal, ...this.pegawaiProfile }
    },
  },
  created() {
    this.getPegawaiProfile()
    this.getPegawaiGrade()
  },
  mounted() {
    this.$nextTick(() => {
      // this.getPegawaiProfile()
    })
  },
  methods: {
    async getPegawaiProfile() {
      try {
        const { data } = await pegawaiProfile.callproc(`${this.thisPegawaiProfile.nip}`)
        const isDataAvailable = data && data.resource.length
        const dataResource = isDataAvailable ? data.resource[0] : ''
        this.pegawaiProfile = dataResource
      } catch (err) {
        console.log(err)
      }
    },
    async getPegawaiGrade() {
      try {
        const param = {
          nip: this.thisPegawaiProfile.nip,
          jenis: 'lengkap',
        }
        const { data } = await pegawaiGrade.callproc(JSON.stringify(param))
        const isDataAvailable = data && data.resource.length
        const dataResource = isDataAvailable ? data.resource[0] : ''
        this.pegawaiGrade = dataResource
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
