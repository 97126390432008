<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="dataProfile.foto"
            :text="dataProfile.nama"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ dataProfile.nama }}
              </h4>
              <span class="card-text">{{ dataProfile.nip }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'pegawai-profile', params: { nip: dataProfile.nip } }"
                variant="primary"
              >
                Profile
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ this.$moment(dataProfile.tanggal_lahir).format('DD MMMM YYYY') }}
              </h5>
              <small>Tanggal Lahir</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-warning"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ this.$moment(dataProfile.tanggal_pensiun).format('DD MMMM YYYY') }}
              </h5>
              <small>Tanggal Pensiun</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ShieldIcon"
                class="mr-50"
              />
            </th>
            <td class="pb-50">
              {{ dataProfile.gol_nama }} ({{ dataProfile.gol_ruang }}) TMT.  {{ this.$moment(dataProfile.tmt_golongan).format('DD MMMM YYYY') }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckSquareIcon"
                class="mr-50"
              />
            </th>
            <td class="pb-50 text-capitalize">
              {{ dataProfile.uraian_status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-50"
              />
            </th>
            <td class="pb-50 text-capitalize">
              {{ dataProfile.jabatan2 }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BriefcaseIcon"
                class="mr-50"
              />
            </th>
            <td class="pb-50">
              {{ dataProfile.uraian_kantor }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-50"
              />
            </th>
            <td>
              {{ dataProfile.nomor_telepon }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    dataProfile: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style>

</style>
